import Swiper, {
  Autoplay,
  Controller,
  EffectCreative,
  Mousewheel,
  Navigation,
} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

const metaCircleBlock = document.getElementsByClassName('meta-circle')[0];
if (metaCircleBlock) {
  const wrapArr = Array.from(
    metaCircleBlock.querySelectorAll('.meta-circle__wrap')
  );
  const itemArr = Array.from(
    metaCircleBlock.querySelectorAll('.meta-circle__item')
  );
  const scrollZone = document.querySelector('.meta-circle-scroll-zone');
  const metaCircleSection = document.getElementById('meta-circle-sec');

  let activeIndex = 0;
  let previousSlideIndex = 0;
  let clickedIndex = 0;
  let activeIndexClass = 0;
  let previousClickedIndex = 0;

  itemArr[activeIndexClass].classList.add('active');

  if (scrollZone) {
    /*scrollZone.addEventListener('wheel', function (event) {
      event.preventDefault();
      if (event.deltaY > 0) {
        rotateAnimation();
        /!*updateActiveClassBackward();*!/
        swiper.slideTo(activeIndex);
        /!*console.log('down');*!/
      } else {
        rotateAnimationBackward();
        /!*updateActiveClass();*!/
        swiper.slideTo(activeIndex);
        /!*console.log('up');*!/
      }
    });*/
  }

  function rotateAnimation() {
    activeIndex = (activeIndex + 1) % itemArr.length;
    wrapArr.forEach((elem, index) => {
      elem.style.transform = `rotate(${
        (activeIndex + index) * step + defaultTransform
      }deg)`;
      elem.children[0].style.transform = `rotate(${
        -1 * ((activeIndex + index) * step + defaultTransform)
      }deg) translateY(-50%)`;
    });
  }

  function rotateAnimationBackward() {
    activeIndex = (activeIndex - 1 + itemArr.length) % itemArr.length;
    wrapArr.forEach((elem, index) => {
      elem.style.transform = `rotate(${
        (activeIndex + index) * step + defaultTransform
      }deg)`;
      elem.children[0].style.transform = `rotate(${
        -1 * ((activeIndex + index) * step + defaultTransform)
      }deg) translateY(-50%)`;
    });
  }

  function updateActiveClass() {
    itemArr[activeIndexClass].classList.remove('active');

    activeIndexClass = (activeIndexClass + 1) % itemArr.length;

    itemArr[activeIndexClass].classList.add('active');
  }

  function updateActiveClassBackward() {
    itemArr[activeIndexClass].classList.remove('active');

    activeIndexClass = (activeIndexClass - 1 + itemArr.length) % itemArr.length;

    itemArr[activeIndexClass].classList.add('active');
  }

  /*function updateActiveClassOnClick(idxSlide, idxItemClick) {
    activeIndex = idxSlide;
    /!*rotateAnimation();*!/
    swiper.slideTo(idxItemClick);
    setTimeout(function () {
      for (let i = 0; i < itemArr.length; i++) {
        itemArr[i].classList.remove('active');
      }
      itemArr[idxItemClick].classList.add('active');
    }, 1000);
  }

  itemArr.forEach((item, i) => {
    item.addEventListener('click', () => {
      console.log(item.dataset.idx);
      console.log(i, activeIndexClass);
      updateActiveClassOnClick(i, Number(item.dataset.idx));
    });
  });*/

  const step = 360 / wrapArr.length;

  let additionalDeg = window.innerWidth <= 851 ? 90 : 0

  let defaultTransform = +metaCircleBlock.getAttribute('data-default-transform').replace('deg', '') + additionalDeg;

  window.addEventListener('resize', () => {
    additionalDeg = window.innerWidth <= 851 ? 90 : 0
    defaultTransform = +metaCircleBlock.getAttribute('data-default-transform').replace('deg', '') + additionalDeg;
  })



  wrapArr.forEach((elem, index) => {
    elem.style.transform = `rotate(${index * step + defaultTransform}deg)`;
    elem.children[0].style.transform = `rotate(${
      -1 * (index * step + defaultTransform)
    }deg) translateY(-50%)`;
  });

  let frameworkSwiperWr = document.querySelector('.framework-swiper-wr');
  let swiperEl = frameworkSwiperWr.querySelector('.swiper');
  let nextEl = frameworkSwiperWr.querySelector('.swiper-button-next');
  let prevEl = frameworkSwiperWr.querySelector('.swiper-button-prev');
  let swiper = new Swiper(swiperEl, {
    modules: [Autoplay, Navigation, Controller, EffectCreative],
    observer: true, //needs to calculate swiper size
    observeParents: true, //needs to calculate swiper size
    spaceBetween: 20,
    slidesPerView: 1,
    threshold: 10,
    navigation: {
      nextEl: nextEl,
      prevEl: prevEl,
    },
    speed: 500,
    effect: 'creative',
    creativeEffect: {
      limitProgress: 3,
      prev: {
        translate: [0, 0, -320],
        opacity: 0,
      },
      next: {
        /*translate: ['calc(100% - 20px)', 0, 0],*/
        translate: ['calc(100% + 20px)', 0, 40],
        opacity: 0,
        effect: 'scale',
        /*scale: 0.79,*/
      },
    },
    /*autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: window.innerWidth >= 640,
    },*/
    on: {
      slideChange: function () {
        if (window.innerWidth <= 851) {
          const currentIndex = swiper.activeIndex;
          let direction = currentIndex > previousSlideIndex ? 'down' : 'up';
          console.log(`Swiper slide changed: ${direction}`);
          console.log(currentIndex, previousSlideIndex)

          const difference = Math.abs(currentIndex - previousSlideIndex);

          if (direction === 'down') {
            if (difference === 1) {
              updateActiveClassBackward();
            } else if (difference === 2) {
              updateActiveClassBackward();
              updateActiveClassBackward();
            } else if (difference === 3) {
              updateActiveClassBackward();
              updateActiveClassBackward();
              updateActiveClassBackward();
            }
          } else {
            if (difference === 1) {
              updateActiveClass();
            } else if (difference === 2) {
              updateActiveClass();
              updateActiveClass();
            } else if (difference === 3) {
              updateActiveClass();
              updateActiveClass();
              updateActiveClass();
            }
          }

          previousSlideIndex = currentIndex;
          console.log(`Rotate to slide: ${swiper.activeIndex}`);
          
          rotateToSlide(swiper.activeIndex);
        }
      },
    },
  });

  let metaCircleScrollZoneSwiperWr = document.querySelector(
    '.meta-circle-scroll-swiper-wr'
  );
  let scrollZoneSwiperEl =
    metaCircleScrollZoneSwiperWr.querySelector('.swiper');
    
  let scrollZoneSwiper = new Swiper(scrollZoneSwiperEl, {
    modules: [Autoplay, Mousewheel, Controller],
    observer: true, //needs to calculate swiper size
    observeParents: true, //needs to calculate swiper size
    spaceBetween: 0,
    slidesPerView: 1,
    threshold: 10,
    direction: 'vertical',
    // mousewheel: {
    //   invert: false,
    //   releaseOnEdges: true,
    //   /*forceToAxis: true,*/
    // },
    on: {
      slideChange: function () {
        if (window.innerWidth > 851) {
          const currentIndex = scrollZoneSwiper.activeIndex;
          let direction = currentIndex > previousSlideIndex ? 'down' : 'up';
          console.log(`Swiper slide changed: ${direction}`);
          console.log(currentIndex, previousSlideIndex)

          const difference = Math.abs(currentIndex - previousSlideIndex);

          if (direction === 'down') {
            if (difference === 1) {
              updateActiveClassBackward();
            } else if (difference === 2) {
              updateActiveClassBackward();
              updateActiveClassBackward();
            } else if (difference === 3) {
              updateActiveClassBackward();
              updateActiveClassBackward();
              updateActiveClassBackward();
            }
          } else {
            if (difference === 1) {
              updateActiveClass();
            } else if (difference === 2) {
              updateActiveClass();
              updateActiveClass();
            } else if (difference === 3) {
              updateActiveClass();
              updateActiveClass();
              updateActiveClass();
            }
          }

          previousSlideIndex = currentIndex;

          rotateToSlide(scrollZoneSwiper.activeIndex);
        } else {
          console.log('MOBILE version')
        }
      },
    },
  });

  scrollZoneSwiper.controller.control = swiper;
  swiper.controller.control = scrollZoneSwiper;

  // metaCircleSection.addEventListener('mouseenter', () => {
  //   jsScrollToEl(
  //     `#meta-circle-sec|500|${
  //       document.documentElement.clientHeight - metaCircleSection.clientHeight
  //     }`
  //   );
  // });

  itemArr.forEach((item, i) => {
    item.addEventListener('click', () => {
      console.log(i, i === 1)
      if (i === 3) {
        swiper.slideTo(1);
      } else if(i === 2) {
        swiper.slideTo(2);
      } else if(i === 1) {
        swiper.slideTo(3);
      } else {
        swiper.slideTo(i);
      }
    });
  });

  function rotateToSlide(index) {
    activeIndex = index % wrapArr.length;
    wrapArr.forEach((elem, idx) => {
      elem.style.transform = `rotate(${
        (activeIndex + idx) * step + defaultTransform
      }deg)`;
      elem.children[0].style.transform = `rotate(${
        -1 * ((activeIndex + idx) * step + defaultTransform)
      }deg) translateY(-50%)`;
    });
  }
}
