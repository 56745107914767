import './utils/vh.utils.js';
import './utils/animate-on-view.utils';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select.component.js';
import './components/header.component.js';
import './components/marquee.component.js';

import './components/touch-us.component.js';
import './components/animations.component.js';
import './components/circle.component.js';

import './components/swiper-studies.component';
import './components/swiper-sectors.component';
import './components/swiper-approach.component';
import './components/swiper-toggles.component.js';
import './components/swiper-specialisms.component.js';
import './components/swiper-team.component.js';
import './components/swiper-partner.component.js';
import './components/toggles.component.js';
import './components/breakdown-radar.component.js';

// import './libs/countUp.lib.js';

window.jsScrollToEl = function jsScrollToEl(targetID) {
  let config = targetID.split('|');
  let duration = +config[1] && +config[1] !== '' ? +config[1] : 800;
  let paddingTop = +config[2] && +config[2] !== '' ? +config[2] : 0;
  let target = config[0];
  let targetElement = document.querySelector(target);
  if (targetElement) {
    let targetOffsetTop = targetElement.offsetTop - paddingTop;
    window.scrollTo({
      top: targetOffsetTop,
      behavior: 'smooth',
    });
    return false;
  }
};


window.closePopup = function closePopup() {
  document.querySelector('#popup').classList.remove('active');
  scrollLock.clearQueueScrollLocks();
  scrollLock.enablePageScroll();
}
