import Swiper, { Autoplay, Navigation } from 'swiper';
// import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let specialismsSwiperWr = document.querySelectorAll('.specialisms-swiper-wr');

specialismsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    swiperOnResize('(max-width: 992px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 16,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false,
      //   pauseOnMouseEnter: false,
      // },
      breakpoints: {
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
    });
  }
});
