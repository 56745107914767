// Titles animation
const allTitles = [...document.getElementsByClassName(`text-animation`)];

if (allTitles.length > 0) {
    // Observer
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    }, { threshold: 0.3 });

    for (let i = 0; i < allTitles.length; i++) {

      // Show titles
      observer.observe(allTitles[i]);

      const wordArr = allTitles[i].textContent.trim().split(" ");
      allTitles[i].innerHTML = '';

      const spanArr = [];

      wordArr.forEach(elem => {
        const span = document.createElement("span");
        span.innerHTML = `${elem}`;
        spanArr.push(span);
      });

      let accum = 0;

      for (let j = 0; j < spanArr.length; j++) {

        const spanFather = document.createElement("span");
        const letterArr = spanArr[j].innerText.trim().split("");

        if (j > 0) accum += spanArr[j - 1].innerText.length;

        for (let k = 0; k < letterArr.length; k++) {
          const span = document.createElement("span");

          span.innerHTML = letterArr[k];

          span.style.cssText = `transition-delay: ${(accum + k) * 50}ms`;

          spanFather.append(span);
        }

        allTitles[i].append(spanFather);

        // Додаємо пробіл між словами через CSS
        if (j < spanArr.length - 1) {
          allTitles[i].append(' '); // Додаємо звичайний пробіл
        }
      }
    }
}
// Parallax

function parallax(e) {
  document.querySelectorAll('.parallax-item').forEach(layer => {
      const speed = layer.getAttribute('data-speed') || 1;

      const x = (window.innerWidth - e.pageX * speed) / 100;
      const y = (window.innerHeight - e.pageY * speed) / 100;

      layer.style.transform = `translateX(${x}px) translateY(${y}px)`;
  });
}

function handleParallax(e) {
  if (window.innerWidth > 768) {
      parallax(e);
  }
}

document.addEventListener("mousemove", handleParallax);

window.addEventListener('resize', () => {
  if (window.innerWidth <= 768) {
      document.querySelectorAll('.parallax-item').forEach(layer => {
          layer.style.transform = '';
      });
  }
});


// Index page typing animation

const typingEl = document.querySelector(".js-typing");

if (typingEl) {
  const typed = new Typed("#typed", {
    stringsElement: "#typed-strings",
    loop: true,
    typeSpeed: 100,
    backSpeed: 40,
    backDelay: 1000,
  });
}