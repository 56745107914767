import Swiper, { Autoplay, Navigation , Pagination} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let partnerSwiperWr = document.querySelectorAll('.partner-swiper-wr');
partnerSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let parentEl = el.parentElement;
    let nextEl = parentEl.querySelector('.swiper-button-next');
    let prevEl = parentEl.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination],
      observer: true,
      observeParents: true,
      spaceBetween: 12,
      slidesPerView: 1,
      threshold: 10,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false,
      //   pauseOnMouseEnter: false,
      // },
      breakpoints: {
        768: {
          spaceBetween: 26,
        },
        1300: {
          slidesPerView: 'auto',
          spaceBetween: 26,
        },
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      on: {
        afterInit: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },

        resize: function () {
          wrapper.scrollWidth >= wrapper.clientWidth
            ? wrapper.classList.remove('justify-center')
            : wrapper.classList.add('justify-center');
        },
      },
    });
    // swiperObserver(swiper);
  }
});
