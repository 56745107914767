const toggles = document.querySelectorAll('.first-internal__toggle');
const togglesTitle = document.querySelector('.toggles-title');

if(toggles.length && togglesTitle) {
  toggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
      toggles.forEach(t => t.classList.remove('active'))
  
      toggle.classList.add('active')
      togglesTitle.innerHTML = toggle.innerHTML
    })
  })
}
